module.exports = {
  abrirJanelaVideo: 'Abrir janela de vídeo',
  aguardeSalaVideo: 'Aguarde enquanto configuramos a sala de atendimento...',
  anexar: 'Anexar',
  anexarArquivo: 'Anexar arquivos',
  anexos: 'Anexos',
  atendimento: 'Em atendimento',
  atendimentos: 'Atendimentos',
  avisoSemConexao: 'Ops! Parece que perdemos a conexão com o servidor de Teleatendimento. Verifique sua conexão com a internet e aguarde, o sistema será recarregado automaticamente assim que a conexão for restabelecida. Se preferir, você pode recarregar manualmente clicando <a href="javascript:window.location.reload(true)"><b>aqui</b></a>.',
  chamadaVideo: 'Iniciar atendimento por vídeo',
  chat: 'Chat',
  cnesConsultorioDesabilitado: cnes => `A instituição com o CNES número ${cnes} não possui um consultório virtual habilitado.`,
  cnesInvalido: cnes => `A instituição com o CNES número ${cnes} não existe.`,
  complementacoes: 'Complementações',
  concluido: 'Concluído',
  concordo: 'Concordo',
  confirmarRegistroAtendimento: 'Você realmente deseja salvar esse registro de atendimento?',
  consultorioVirtual: 'Consultório virtual',
  digiteMensagem: 'Digite sua mensagem...',
  duvidaPrincipal: 'Dúvida principal',
  encaminhando: 'Encaminhando...',
  encaminharAtendimento: 'Encaminhar atendimento',
  enviandoArquivo: 'Enviando arquivo...',
  enviarMensagem: 'Enviar mensagem',
  erroConectarBBB: 'Erro ao conectar na sala de áudio e vídeo. Deseja tentar novamente?',
  espera: 'Em espera',
  fecharAtendimento: 'Fechar janela de atendimento',
  fecharChamadaVideo: 'Fechar atendimento por vídeo',
  fecharHistorico: 'Fechar histórico',
  finalizarAtendimento: 'Finalizar atendimento',
  historico: 'Histórico',
  historicoAtendimento: 'Histórico dos atendimentos',
  historicoSaude: 'Histórico de saúde',
  inserirAnexo: 'Inserir anexo',
  menuAtendimento: 'Atendimento',
  nenhumaMensagemEnviadaAtendimento: 'Nenhuma mensagem de texto foi enviada neste atendimento.',
  nenhumAnexoEnviadaAtendimento: 'Nenhum anexo foi enviado neste atendimento.',
  nenhumRegistroAtendimento: 'Nenhum registro foi enviado neste atendimento.',
  notificacaoNovaMensagemAnexo: 'Envio um anexo.',
  notificacaoNovoAtendimento: 'Você já está realizando um atendimento neste momento. Se você iniciar um novo atendimento ou visualizar as mensagens de outro atendimento, o atual será finalizado. Você confirma esta ação?',
  notificacaoSolicitacaoAtendimento: 'Um usuário solicitou atendimento.',
  notificacaoTabNovaMensagem: '✉️ Nova mensagem enviada',
  notificacaoTabSolicitacaoAtendimento: '🟠 Atendimento solicitado',
  placeholderDuvidaPrincipal: 'A partir da descrição do caso apresentado, qual a dúvida do paciente?',
  placeholderHistoricoSaude: 'Descrever hábitos e comorbidades do paciente que possam influenciar suas condições de saúde, além de internações, cirurgias e tratamentos anteriores que sejam pertinentes ao caso.',
  placeholderRelatoCaso: 'Descrever o caso com clareza, apontando a história clínica atual de forma detalhada.',
  placeholderResultadoExames: 'Listar os resultados dos exames laboratoriais e de imagem que contribuam com a análise do caso.',
  placeholderTratamentosAndamento: 'Listar medicamentos e tratamentos alternativos em uso.',
  registros: 'Registros',
  registroAtendimento: 'Registro do atendimento',
  registroAtendimentoSalvoSucesso: 'Registro de atendimento salvo com sucesso.',
  relatoCaso: 'Relato do caso',
  relatosPaciente: 'Relatos do paciente',
  resultados: 'Resultados de exames',
  resultadosExames: 'Resultados de exames laboratoriais ou de imagem',
  selecionarEmoji: 'Selecionar emoji',
  selecioneArquivo: 'Selecione um arquivo',
  selecioneInstituicao: 'Instituição para atendimento',
  selecioneUsuario: 'Selecione um usuário para iniciar ou continuar um atendimento.',
  semAtendenteEncaminhar: 'Não há outros atendentes disponíveis para encaminhar o atendimento.',
  solicitarAtendimento: 'Solicitar atendimento',
  suporte: 'Suporte',
  teleatendimento: 'Teleatendimento',
  termo: 'Termo de consentimento',
  textoAguarde: nome => `Olá, ${nome}!`,
  textoAguardeAtendimento: 'Aguarde seu atendimento.',
  textoAguardeSemAtendentes: 'No momento não há atendentes.',
  textoAtendenteConectado: 'Atendente conectado, por favor retire sua dúvida.',
  textoAtendimentoFinalizado: 'O atendente encerrou este atendimento.',
  textoCliqueAbrirHistorico: 'Verifique seu histórico <a href="javascript:void(0)"><b>aqui</b></a>.',
  textoCliqueSolicitarAtendimento: 'Solicite seu atendimento <a href="javascript:void(0)"><b>aqui</b></a>.',
  tratamentosAndamento: 'Tratamentos em andamento',
  validar: 'Validar'
};