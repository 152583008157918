module.exports = {
  acompanhanteRegistrado: 'Acompanhante já registrado',
  atencaoAdicaoAnexos: 'Anexe aqui os documentos do paciente para a avaliação clínica.',
  atencaoAnexosObrigatorios: 'Os documentos de identidade com CPF, cartão nacional de saúde, comprovante de residência, termo de responsabilidade SMS, assinado e resultado da gasometria arterial são obrigatórios.',
  avaliacaoClinica: 'Avaliação clínica',
  cancelado: 'Cancelado',
  cardiopatias: 'Cardiopatias',
  doencaAutoimune: 'Doença autoimune',
  doencaNeurologica: 'Doença neurológica',
  doencasPulmonares: 'Doenças pulmonares',
  exFumante: 'Ex-fumante',
  fluxoOxigenio: 'Fluxo de Oxigênio',
  fumante: 'Fumante',
  gasometriaArterial: 'Gasometria arterial',
  historicoSaude: 'Histórico de saúde',
  identificacaoResponsavel: 'Identificação do responsável',
  informacoesClinicas: 'Informações clínicas',
  neoplasia: 'Neoplasia',
  novaAvaliacaoClinica: 'Nova avaliação clínica',
  nuncaFumou: 'Nunca fumou',
  outrasCheckbox: 'Outra(s)',
  outroParentesco: 'Outro parentesco',
  pacienteInternado: 'Paciente internado',
  parecerSituacao: 'Parecer da situação',
  parentesco: 'Grau de parentesco',
  rascunho: 'Rascunho',
  resultados: 'Resultados',
  solicitacaoAvaliacaoClinica: 'Solicitação de avaliação clínica',
  tempoUsoOxigenio: 'Tempo de uso do oxigênio (h/dia)',
  traqueostomia: 'Traqueostomia',
  tratamentosAndamento: 'Tratamentos em andamento',
  veracidadeParecerSituacao: 'Assumo a veracidade das informações acima descritas, com base em visita domiciliar, documentação apresentada, bem como das declarações recebidas do paciente.'
};